import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import '../assets/stylesheets/index.scss';

export const siteQuery = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        title
        keywords
        description
        tdsPath
        tmPath
      }
    }
  }
`

const onScroll = () => {
  if (typeof window !== 'undefined') {
    window.onscroll = () => {
      const navbar = document.querySelector(".menu-holder")
      const sticky = navbar.offsetTop
  
      if (window.pageYOffset > sticky) {
        navbar.classList.add("fixed-nav")
      } else {
        navbar.classList.remove("fixed-nav")
      }
    }
  }
}

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={siteQuery}
      render={({ site: { siteMetadata } }) => (
        <>
          {/* prettier-ignore */}
          <Helmet defaultTitle="Trailblazer Bootcamp">
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta charset="utf-8" />

            <script src="https://developer.salesforce.com/resources2/bootcamps2022/Atlanta/js/jquery.min.js"></script>

            <link 
              rel="stylesheet" 
              href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap.min.css" 
              integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u" 
              crossorigin="anonymous" />

            <link 
              rel="stylesheet" 
              href="https://developer.salesforce.com/resources2/bootcamps2022/bootstrap-fix.css?v=1" >
            </link>
              
            {/*<script src="https://developer.salesforce.com/resources2/bootcamps2022/Atlanta/js/jquery.easing.min.js"></script>*/}

            <script src={`${siteMetadata.tdsPath}/scripts/th-element-loader.js`}></script>
            <script src={`${siteMetadata.tmPath}/modules/tm.js`} type="module"></script>
            <script src={`${siteMetadata.tdsPath}/modules/tds.js`} type="module"></script>
            <link href={`${siteMetadata.tdsPath}/css/tds-theme-provider.css`} rel="stylesheet" />
            <link href={`${siteMetadata.tdsPath}/css/tds.css`} rel="stylesheet" />
            
            <script 
              src="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/js/bootstrap.min.js" 
              integrity="sha384-Tc5IQib027qvyjSMfHjOMaLkfuWVxZxUPnCJA7l2mCWNIpG9mGCD8wGNIcPD7Txa" 
              crossOrigin="anonymous"
              defer>
            </script>

          </Helmet>
          <div className="bootcamp-site">
            {onScroll()}
            {children}

            
          </div>
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
